import { graphql, Link, useStaticQuery } from 'gatsby'
import get from 'lodash/get'
import React from 'react'
import Icon from '../common/Icon'

export default function Team() {
  const data = useStaticQuery(graphql`
    query AwardQuery {
      allContentfulAward(sort: { fields: [order], order: ASC }) {
        nodes {
          link
          text
          order
        }
      }
    }
  `)

  const awards = get(data, 'allContentfulAward.nodes', null)

  return (
    <div className="bg-white flex flex-col max-w-7xl mx-auto px-8 md:px-20 my-20">
      <h2 className="text-3xl font-bold tracking-tight text-left md:text-center text-gray-900 sm:text-4xl">
        Award winning product range
      </h2>
      <div class="flex flex-col max-w-7xl mt-6 mx-auto">
        {awards.map((award, index) => (
          <Link
            to={award.link}
            key={index}
            target="_blank"
            className="flex flex-row align-middle items-center my-2 w-full"
          >
            {/* {award.image && (
              <GatsbyImage
                alt={award.imageAlt}
                image={getImage(award.image.gatsbyImage)}
                className="h-12 w-64 mx-auto object-contain mb-1"
              />
            )} */}
            <Icon
              name="TrophyIcon"
              className={'text-uforange-600 h-6 w-6 mr-2'}
            />
            <p className="text-gray-600 w-full">{award.text}</p>
          </Link>
        ))}
      </div>
    </div>
  )
}
