import React from 'react'

export default function Team() {
  return (
    <div className="flex flex-col w-full">
      <div className="bg-white w-full flex flex-col lg:flex-row pb-20 max-w-7xl mx-auto px-6 align-bottom justify-end mb-12 gap-12">
        <div className="bg-white flex flex-col w-full lg:w-1/2 max-w-5xl gap-6 mx-auto">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Meet our team
          </h2>
          <p className="mx-auto w-full text-lg leading-8 text-gray-600">
            Our dynamic team bring a wealth of experience from the
            infrastructure, EV charging, cleantech and fintech industries. We
            have unparalleled expertise in delivering seamless service delivery,
            regardless of project size.
          </p>
          <p className="mx-auto w-full text-lg leading-8 text-gray-600">
            We are passionate about driving environmental change and helping to
            create a sustainable, net-zero future. That’s why we work hard to
            help facilitate the UK’s transition to EVs by ensuring chargepoints
            are accessible by all.
          </p>
        </div>
        <div className="bg-white flex flex-col w-full lg:w-1/2 lg:flex-row max-w-5xl mx-auto gap-8 mt-6 md:mt-20">
          <img
            src="/images/about/oli.jpg"
            alt="team"
            className="w-full md:w-56 h-64 lg:h-48 rounded object-cover"
          />
          <div className="flex flex-col gap-6">
            <h2 className="text-uforange-600">A message from our CEO</h2>
            <p className="mx-auto w-full text-lg leading-8 text-gray-600">
              "Electric mobility is fundamental to reaching net zero targets.
              Our team are on a mission to to deploy an easy to use nationwide
              EV chargepoint network to accelerate us to net zero”
            </p>
            <p className="mx-auto w-full font-bold text-lg leading-8 text-uforange-600">
              Oli Freeling-Wilkinson, CEO
            </p>
          </div>
        </div>
      </div>
      <img
        src="/images/about/team.jpg"
        alt="urbanfox team"
        className="object-cover h-96 lg:h-144 w-full"
      />
    </div>
  )
}
