import React from 'react'
import Awards from '../components/about/Awards'
import Team from '../components/about/Team'
import Button from '../components/common/Button'
import Header from '../components/common/Header'
import AppCTA from '../components/cta/AppCTA'
import GeneralCTA from '../components/cta/GeneralCTA'
import NewsletterCTA from '../components/cta/NewsletterCTA'
import Layout from '../components/layout'
import Seo from '../components/seo'
class RootIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo
          title="About us | Urban Fox | EV Charging Network"
          description="Learn about the innovative, retractable charging solutions like the UEone and our award winning team"
        />
        <Header filter="About" />
        <div className="bg-white flex flex-col max-w-5xl mx-auto px-8 md:px-20 gap-20 my-20">
          <div className="mx-auto max-w-4xl text-left md:text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Powering an EV-ready future accessible by all
            </h2>
            <p className="mx-auto mt-6 w-full text-lg text-left md:text-center leading-8 text-gray-600">
              Urban Fox is a British EV chargepoint operator with the capacity
              and capability to deliver EV charging networks at scale. We
              empower local councils and property developers with a
              comprehensive range of innovative, low impact, near-home EV
              charging solutions, including our award-winning fully retractable,
              flush-fitting UEone chargepoint.
            </p>
            <p className="mx-auto mt-6 w-full text-lg text-left md:text-center leading-8 text-gray-600">
              We leverage Balfour Beatty’s wealth of experience delivering
              complex, multi-site projects in the infrastructure and energy
              sectors. We have the expertise and financial strength to deliver
              the right chargepoint, in the right place, at the right time.
            </p>
            <p className="mx-auto mt-6 w-full text-lg text-left md:text-center leading-8 text-gray-600">
              We believe everyone should be given the opportunity to transition
              to EVs, no matter where they park their cars at night. Our
              near-home charging solutions are empowering communities and
              ensuring no one is left behind on the road to net-zero.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Button label="Contact us" href="/contact" />
            </div>
          </div>
        </div>
        <div className="bg-white flex flex-col max-w-5xl mx-auto px-8 md:px-20 gap-20 my-20">
          <div className="mx-auto max-w-4xl text-left md:text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Innovation and infrastructure expertise
            </h2>
            <p className="mx-auto mt-6 w-full text-lg text-left md:text-center leading-8 text-gray-600">
              Urban Fox Networks is a partnership between Balfour Beatty
              Investments (BBI), the international infrastructure investor, and
              Urban Electric Networks, a British EV chargepoint operating
              company.
            </p>
            <p className="mx-auto mt-6 w-full text-lg text-left md:text-center leading-8 text-gray-600">
              As BBI’s first entry into the EV charging market, the company
              invested £60 million into the partnership, with plans to deliver
              up to 35,000 chargepoints across the UK over the next decade.
            </p>
            <p className="mx-auto mt-6 w-full text-lg text-left md:text-center leading-8 text-gray-600">
              Urban Fox combines Urban Electric Networks’ innovative and
              entrepreneurial spirit with Balfour Beatty’s unmatched scale,
              skill and capability to deliver infrastructure in the heart of
              local communities.
            </p>
          </div>
        </div>
        <Team />
        <Awards />
        <NewsletterCTA />
        <AppCTA />
        <GeneralCTA />
      </Layout>
    )
  }
}

export default RootIndex
